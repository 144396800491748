import { Grid } from "@chakra-ui/react";
import { GetStaticProps, NextPage } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Txt } from "src/components/atoms/Txt";
import { gradients } from "src/lib/theme/gradients";
import { useCurrentUserContext } from "src/providers/UserProvider";
import { ActionCard } from "../components/molecules/ActionCard";

const HomePage: NextPage = () => {
  const user = useCurrentUserContext();

  const isLensPostingEnabled =
    user.lensCheckRelay.connectedLensProfile &&
    user.lensCheckRelay.isLensApiDispatcherSet &&
    user.lensCheckRelay.apiLogin;

  const isCCProfileConnected = user.profile.ccProfiles.length > 0;

  const { t } = useTranslation();
  return (
    <Grid gap={6}>
      <Txt textStyle="h2" maxW="14ch" mx="auto">
        {t("Home.Title")}
      </Txt>
      <Grid
        p={6}
        gap={6}
        templateRows={{ base: "1fr", md: "repeat(2, 1fr)" }}
        templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}
        justifyItems="center"
        maxW="860px"
        mx="auto"
      >
        <ActionCard
          gridColumn={{ base: "unset", md: "1/3" }}
          imageUrl="/images/lens-logo.png"
          name={
            isLensPostingEnabled
              ? t("Home.LensPostingEnabled")
              : t("Home.ConnectMyLensProfile")
          }
          href="/sign-in-lens"
          textColor={isLensPostingEnabled ? "lensLightgreen" : "white"}
          width={172}
          height={172}
          isDisabled={isLensPostingEnabled}
          {...(isLensPostingEnabled ? gradients.lens : gradients.blue)}
          boxShadow={
            isLensPostingEnabled
              ? "0px 4px 29px rgba(171, 254, 44, 0.28)"
              : "headerGlassEffect"
          }
        />
        <ActionCard
          gridColumn={{ base: "unset", md: "3/5" }}
          imageUrl="/images/cyberconnect-logo.png"
          name={
            isCCProfileConnected
              ? t("CCProfileConnected")
              : t("Home.ConnectCCProfile")
          }
          href="/cyberconnect"
          width={172}
          height={172}
          isDisabled={isCCProfileConnected}
          {...(isCCProfileConnected ? gradients.cyberconnect : gradients.blue)}
          boxShadow={
            isCCProfileConnected
              ? "0px 4px 29px rgba(255, 255, 255, 0.28)"
              : "headerGlassEffect"
          }
        />
        <ActionCard
          gridColumn={{ base: "unset", md: "2/4" }}
          imageUrl="/images/project-icons.png"
          name={t("Home.VerifyMyNFTs")}
          href="verify-nfts"
          width={1116}
          height={580}
        />
      </Grid>
    </Grid>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale = "en" }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["connect"])),
    },
  };
};

export default HomePage;
